<template>
  <CommonTooltip :tooltip="$t('cars-list.bank11-tooltip')">
    <DynamicAuctionItemListCarIcon
      variant="purple"
      size="small"
      v-bind="$attrs"
    >
      <img src="../../img/bank11.png" :alt="$t('cars-list.bank11-image-alt')" />
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>
</template>
